import React, { PureComponent } from 'react';
import classNames from 'classnames';

import FileIcon from '../icons/FileIcon';
import SendIcon from '../icons/SendIcon';
import DnDArea from './DnDArea';
import Buttons from './Buttons';
import FileInfo from './FileInfo';
import localization from '../../../../localization';
const t = localization.translate;

export default class Form extends PureComponent {
  textInput = null;
  fileInput = null;

  state = {
    loadProgress: 0,
    fileResponse: null,
    file: null,
  };

  checkFormHasValues = () =>
    Boolean(this.textInput?.value?.trim() || this.state.fileResponse);

  setTextRef = (t) => {
    this.textInput = t;
    this.props.setTextInput(t);
  };

  setFileRef = (f) => {
    this.fileInput = f;
    this.props.setImageInput(f);
  };

  clearFile = () => {
    this.setState({ loaded: 0, fileResponse: null, file: null });
    if (this.fileInput) {
      this.fileInput.value = '';
    }
    this.props.actions.clearFile();
  };

  onUploadProgress = ({ loaded, total }) =>
    this.setState({ loadProgress: Math.round((loaded * 100) / total) });

  uploadFile = async () => {
    const fileResponsePromise = this.props.actions.sendFileToStorage(
      this.onUploadProgress
    );
    this.setState({ file: this.fileInput.files[0] });
    const fileResponse = await fileResponsePromise;
    this.setState({ fileResponse });
  };

  onSendButton = async (e) => {
    if (e?.type === 'submit') {
      e.preventDefault();
      e.stopPropagation();
    }
    const {
      actions: { onAnswerButtonClick },
    } = this.props;

    try {
      await onAnswerButtonClick(null);
    } catch (e) {
      console.error(e);
    } finally {
      this.clearFile();
    }
  };

  onChange = () => this.forceUpdate();

  onPaste = async (e) => {
    const data = (e.clipboardData || e.originalEvent.clipboardData).items?.[0];
    if (!data || (data.type === 'text/plain' && data.kind === 'string')) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.file = data.getAsFile() || null;

    if (this.file) {
      const fileList = new DataTransfer();
      fileList.items.add(this.file);
      this.fileInput.files = fileList.files;
      this.uploadFile();
    }
  };

  render() {
    const {
      data,
      actions,
      blockForm,
      sendBtn,
      fileUpload,
      setActionsWrapper,
      wrapperRef,
      buttons,
      buttonClick,
      placeholder,
      toggleWidget,
    } = this.props;

    const { captions, forcedLanguage } = data;
    const { loadProgress, file } = this.state;
    const confirmIsDisabled = this.checkFormHasValues() === false;
    const disabledForm =
      blockForm && Boolean(buttons?.find((x) => typeof x === 'string'));

    return (
      <>
        <Buttons
          testMode={this.props.testMode}
          toggleWidget={toggleWidget}
          options={data}
          buttons={buttons}
          classname="justwidget--buttons-top"
          buttonClick={buttonClick}
        />
        <div className="justwidget--container-form">
          <form
            className={classNames(
              'justwidget--form',
              disabledForm && 'justwidget--form_disabeld'
            )}
            onSubmit={this.onSendButton}
          >
            <div
              className="justwidget--actions"
              ref={(a) => setActionsWrapper(a)}
            >
              <textarea
                rows="1"
                maxLength={data.inputMaxLen}
                className={classNames('justwidget--text', {
                  'justwidget--has-file': file,
                  'justwidget--has-buttons': sendBtn || fileUpload,
                })}
                data-test-id="Justwidget.TextArea"
                onChange={this.onChange}
                placeholder={placeholder}
                ref={this.setTextRef}
                disabled={disabledForm}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 || e.key === 'Enter') {
                    e.preventDefault();
                    this.onSendButton();
                  } else {
                    actions.onClearText(e);
                  }
                }}
                onPaste={this.onPaste}
              />

              {(sendBtn || fileUpload) && (
                <div className="justwidget--text-controls">
                  {sendBtn && !data.send && (
                    <button
                      className="justwidget--send"
                      data-test-id="Justwidget.SendButton"
                      disabled={confirmIsDisabled}
                      onClick={this.onSendButton}
                    >
                      <SendIcon />
                    </button>
                  )}

                  {fileUpload && (
                    <div
                      className={classNames('justwidget--image-upload', {
                        'justwidget--hidden': file,
                      })}
                    >
                      <label>
                        <FileIcon />
                        <input
                          name="image"
                          type="file"
                          onChange={this.uploadFile}
                          ref={this.setFileRef}
                        />
                      </label>
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
          {file && (
            <FileInfo
              clearFile={this.clearFile}
              loadProgress={loadProgress}
              file={file}
            />
          )}
        </div>
        {fileUpload && !file && (
          <DnDArea
            fileRef={this.fileInput}
            label={forcedLanguage ? t('captions.dndLabel') : captions.dndLabel}
            uploadFile={this.uploadFile}
            wrapperRef={wrapperRef}
          />
        )}
        <Buttons
          buttons={buttons}
          classname="justwidget--buttons-bottom"
          buttonClick={buttonClick}
        />
      </>
    );
  }
}
