import React from 'react';
import ReactDOM from 'react-dom';
import JustWidget from './App';
import { isDev } from './utils';

if (isDev()) {
  /* window.JustWidgetAccountId = 1000001;
  window.JustWidgetToken = 'DdgSSSOs:d60a101538c9d0d4b67b9dd98eba87224792f99c';
  window.JustWidgetBasePath = '//localhost:9020'; //10.0.2.2 для virtual box

  window.JustWidgetAttributes = {
    theme: 'defaultv3',
    options: {
      language: 'ru',
      hidePoweredByJustAI: false,
      closeControls: { show: true },
      names: { show: true, assistantName: 'McDonalds', userName: 'Вы' },
      captions: { inputPlaceholder: 'Ваше сообщение' },
      headline: { text: 'DDSFSDF', show: true, showLogo: false },
      inputMaxLen: '400',
      avatar: { show: true },
      sendBtn: { show: true },
      blockForm: { show: true },
      fileUpload: { show: true, uploadErrorMessage: '', tooBigFileMessage: '' },
      fileDownload: { text: 'Скачать' },
      voiceTest: { show: false, speaker: 'oksana' },
      bubble: {
        show: true,
        bubbletext: 'Привет пузырь',
        mobileBubbletext: 'Мобильный пузырь',
        delay: '1',
      },
      blinkOnNewMessage: true,
      positionOffset: { bottom: 50, right: 68 },
      font: 'Roboto',
      sizes: {
        font: 'small',
        avatar: 'big',
      },
      palette: {
        headlineBackgroundColor: '#5A9CED',
        headlineTextColor: 'white',
        chatBackgroundColor: '#FFFFFF',
        chatBackgroundOpacity: 100,
        userMessageBackgroundColor: '#CBDFF8',
        userMessageTextColor: 'dark',
        botMessageBackgroundColor: '#F4F5F5',
        botMessageTextColor: 'dark',
        formButtonsColor: '#5A9CED',
        formTextColor: '#000',
      },
      sessionByUserMessage: {
        show: false,
        welcomemessage: 'text',
        delay: 0, //FIXME 100 секунд??? в виджете это 100мс. сломаный конфиг,
        buttonsShow: false,
        buttons: [
          { text: 'google', url: 'https://google.com' },
          { text: 'asdasdasd' },
        ],
      },
      buttonsSamePlace: true,
      soundNotification: {
        option: 'client_bot', //['client_bot', 'bot', 'client', 'none']
        sound:
          'https://notificationsounds.com/storage/sounds/file-sounds-1123-for-sure.mp3',
      },
    },
    position: 'left',
    positionVertical: 'bottom',

    percentage: '100',
  }; */
}

if (!window.JustWidgetSendRawData) {
  window.JustWidgetSendRawData = function () {
    return window.JustWidgetRawParams ? window.JustWidgetRawParams : {};
  };
}

let container = null;
if (window.JustWidgetAttributes?.options) {
  if (
    window.JustWidgetAttributes.options.embedmentContainer?.enabled &&
    window.JustWidgetAttributes.options.embedmentContainer?.id
  ) {
    container = document.getElementById(
      window.JustWidgetAttributes.options.embedmentContainer?.id
    );
  }
  if (!container && window.JustWidgetAttributes.options.embedmentContainerId) {
    container = document.getElementById(
      window.JustWidgetAttributes.options.embedmentContainerId
    );
  }
}
if (!container) {
  container = document.getElementById('widget-root');
}
if (!container) {
  container = document.createElement('div');
  container.id = 'widget-root';
  document.body.appendChild(container);
}

ReactDOM.render(<JustWidget />, container);
