import React from 'react';

export default function SendIcon() {
    return <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none">
        <path d="M7.89221 0.914114L7.24838 1.55794C7.096 1.71032 7.096 1.9574 7.24838 2.10981L12.2929 7.15433H1.19018C0.974678 7.15433 0.799957 7.32905 0.799957 7.54455V8.45506C0.799957 8.67055 0.974678 8.84527 1.19018 8.84527H12.2929L7.24838 13.8898C7.096 14.0422 7.096 14.2893 7.24838 14.4417L7.89221 15.0855C8.04459 15.2379 8.29167 15.2379 8.44408 15.0855L15.2538 8.27575C15.4062 8.12337 15.4062 7.8763 15.2538 7.72388L8.44405 0.914114C8.29167 0.761701 8.04459 0.761701 7.89221 0.914114Z" fill="white"/>
    </svg>

}