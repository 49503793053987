import React, { Component } from 'react';
import classNames from 'classnames';
import fonts from 'google-fonts';

import Voice from './components/Voice';
import Form from './components/Form';
import ImageOverlay from './components/ImageOverlay';
import VoiceController from './components/Voice/VoiceController';
import StacktraceOverlay from './components/StacktraceOverlay';
import WidgetStarter from './components/WidgetStarter';
import Header from './components/Header';
import Messages from './components/Messages';
import Style from './components/Style';
import PoweredLabel from './components/PoweredLabel';
import localization from '../../localization';

const t = localization.translate;

export default class WidgetBody extends Component {
  innerRef = null;
  innerMaxHeight = 70;

  componentWillMount() {
    const {
      data: {
        options: { notUseStyles, font },
      },
    } = this.props;
    if (!notUseStyles && font) {
      fonts.add({
        [font]: [400, 500, 600, 700, 300, '&subset=latin,cyrillic'],
      });
    } else if (!notUseStyles) {
      fonts.add({
        Roboto: [400, 500, 600, 700, 300, '&subset=latin,cyrillic'],
      });
    }
  }

  componentDidUpdate(prevProp) {
    const {
      data: {
        options: { notUseStyles, font },
      },
    } = this.props;
    const {
      data: {
        options: { font: oldFont },
      },
    } = prevProp;
    if (font !== oldFont && !notUseStyles) {
      fonts.add({
        [font]: [400, 500, 600, 700, 300, '&subset=latin,cyrillic'],
      });
    }
  }

  setMaxHeight = (maxHeightDelta) => {
    this.innerMaxHeight -= (maxHeightDelta * 100) / window.innerHeight;
    const verticalOffset =
      (((this.props.data.options?.positionOffset?.bottom || 24) + 24) * 100) /
      window.innerHeight;
    if (this.innerMaxHeight > 100 - verticalOffset)
      this.innerMaxHeight = 100 - verticalOffset;
    this.innerRef.parentNode.style.maxHeight = `${this.innerMaxHeight}vh`;
    this.innerRef.style.maxHeight = `${this.innerMaxHeight}vh`;
  };

  render() {
    const {
      data,
      state,
      wrapperRef,
      functions,
      getDomFunctions,
      getInstances,
      touchClass,
      testMode,
    } = this.props;
    const {
      closeControls,
      logo,
      bubble,
      headline: rawHeadline,
      voiceTest,
      captions,
      positionOffset,
      palette,
      sizes,
      font,
      hidePoweredByJustAI,
      poweredLabel,
      poweredLink,
    } = data.options;

    const lastMessage =
      state.messages?.length > 0 &&
      [...state.messages].reverse().find((x) => x.text);
    const isOperator = lastMessage && Boolean(lastMessage.text?.operatorInfo);

    let headline = rawHeadline;
    if (data.options.forcedLanguage && headline) {
      headline.text = t('name');
    }

    return (
      <>
        <Style palette={palette} sizes={sizes} font={font} />
        <div
          className={classNames(
            `justwidget  mobile_${data.position} bottom`,
            touchClass,
            { justwidget_hidden: !state.isOpen }
          )}
          data-test-id="Justwidget"
          style={{
            maxHeight: `${this.innerMaxHeight}vh`,
            bottom: positionOffset.bottom,
            right: positionOffset.right,
          }}
          onDragOver={() =>
            wrapperRef?.classList?.add('justwidget--dnd-container')
          }
          ref={(jw) => getDomFunctions.setJustWidget(jw)}
        >
          <WidgetStarter
            closeControls={closeControls}
            position={data.position}
            toggleWidget={functions.toggleWidget}
            showBubble={bubble.show}
            data={data}
            logo={logo}
            showCloseButton={data.options.showCloseButton}
          />
          <div
            ref={(inner) => (this.innerRef = inner)}
            style={{ maxHeight: `${this.innerMaxHeight}vh` }}
            className="justwidget--inner"
          >
            <Header
              headline={headline}
              testMode={testMode}
              logo={logo}
              setMaxHeight={this.setMaxHeight}
              sendStart={functions.sendStart}
              sendNewSession={functions.sendNewSession}
              soundControl={this.props.soundControl}
              closeControls={{
                options: closeControls,
                show: !headline.show,
                toggle: functions.toggleWidget,
              }}
            />
            <Messages
              data={data}
              state={state}
              getDomFunctions={getDomFunctions}
              functions={functions}
            />

            {voiceTest.show ? (
              <VoiceController
                attributes={data}
                getVoiceInstance={getInstances.getVoiceInstance}
                sendData={functions.sendData}
                handleText={functions.handleText}
              >
                {(props) => <Voice {...props} />}
              </VoiceController>
            ) : (
              <Form
                toggleWidget={functions.toggleWidget}
                testMode={testMode}
                fileUpload={data.options.fileUpload.show}
                wrapperRef={wrapperRef}
                sendBtn={data.options.sendBtn.show}
                blockForm={!isOperator && data.options.blockForm.show}
                inputMaxLen={data.options.inputMaxLen}
                showSendBtn={false}
                buttons={
                  !data.options.buttonsSamePlace
                    ? state.buttons.filter(
                        (button) => typeof button === 'string'
                      )
                    : state.buttons
                }
                buttonClick={functions.buttonClick}
                placeholder={
                  data.options.forcedLanguage
                    ? t('captions.inputPlaceholder')
                    : captions.inputPlaceholder
                }
                data={data.options}
                setTextInput={getDomFunctions.setTextInput}
                setImageInput={getDomFunctions.setImageInput}
                setActionsWrapper={getDomFunctions.setActionsWrapper}
                actions={{
                  onAnswerButtonClick: functions.onAnswerButtonClick,
                  sendFileToStorage: functions.sendFileToStorage,
                  onClearText: functions.onClearText,
                  clearFile: functions.clearFile,
                }}
              />
            )}
            {!hidePoweredByJustAI && (
              <PoweredLabel label={poweredLabel} link={poweredLink} />
            )}
          </div>

          {Boolean(state.image) && (
            <ImageOverlay
              label={
                data.options.forcedLanguage
                  ? t('captions.imageDownload')
                  : captions.imageDownload
              }
              hideImageOverlay={functions.hideImageOverlay}
              src={state.image}
            />
          )}
          {Boolean(state.stacktrace) && (
            <StacktraceOverlay
              data={data}
              hideErrorOverlay={functions.hideErrorOverlay}
              stacktrace={state.stacktrace}
            />
          )}
        </div>
      </>
    );
  }
}
